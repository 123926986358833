import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "swiper/css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "./common/css/index.scss";
import "animate.css";
const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(store);
app.use(router);
app.use(ElementPlus);
app.mount("#app");
