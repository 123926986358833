<template>
  <div class="home_content">
    <div class="content_box">
      <topNav pageName="home"></topNav>
    </div>
    <div class="swiper_box">
      <swiper
        :modules="modules"
        :loop="true"
        :slides-per-view="1"
        :space-between="0"
        :autoplay="{ delay: 5000, disableOnInteraction: false }"
        :navigation="navigation"
        :pagination="{ clickable: true }"
        :scrollbar="{ draggable: false }"
      >
        <swiper-slide
          ><img src="/static/home/banner.png" style="width: 100%"
        /></swiper-slide>
        <swiper-slide
          ><img src="/static/home/image_2.png" style="width: 100%"
        /></swiper-slide>
        <swiper-slide
          ><img src="/static/home/image_3.png" style="width: 100%"
        /></swiper-slide>
      </swiper>
      <!-- <img class="logo" src="/static/home/logo.png" /> -->
      <!-- <img
        class="phone wow animate__fadeInRight"
        src="/static/home/phone.png"
      /> -->
    </div>
    <div>
      <img class="imgcenter" src="/static/home/banner2.png" />
    </div>
	<div>
	  <img class="imgcenter" src="/static/home/image_6.png" />
	</div>
<!--    <div class="content_product">
      <div class="content_product_top">
        <div>产品及服务</div>
      </div>
      <div class="content_product_center">
        <div class="procutxiangmu1">
          <div class="xiangmucss">养生SPA</div>
          <div class="xiangmuwenzi">
            全身芳香SPA 芳香SPA+热灸 芳香SPA+眼护 泰式古法 精油开背
          </div>
        </div>
        <div class="procutxiangmu1">
          <div class="xiangmucss">女性养护</div>
          <div class="xiangmuwenzi">女神尊享SPA 暖宫养护</div>
        </div>
        <div class="procutxiangmu1">
          <div class="xiangmucss">调理项目</div>
          <div class="xiangmuwenzi">
            头颈深层减压 全身经络舒压 运动肌能养护 脊柱精准调理
          </div>
        </div>
      </div>
      <div class="content_product_middle">
        <div class="btndetail">查看详情</div>
      </div>
    </div> -->

<!--    <div class="shangjiamodel">
      <div class="jishititle">优秀商家展示</div>
      <div class="jishipagetubiao1">
        <img class="pagecss" src="/static/home/lastpage.png" />
      </div>
      <div class="jishipagetubiao1">
        <img class="pagecss" src="/static/home/nextpage.png" />
      </div>
    </div>
    <div class="shangjiamodel">
      <div class="navcity">
        <div class="weixuanzhogn xuanzhong">——— 重庆</div>
        <div class="weixuanzhogn">上海</div>
        <div class="weixuanzhogn">武汉</div>
        <div class="weixuanzhogn">成都</div>
      </div>
      <div class="navimg">
        <img class="jishicssimg" src="/static/home/jishi_1.png" />
        <img class="jishicssimg" src="/static/home/jishi_2.png" />
        <img class="jishicssimg" src="/static/home/jishi_3.png" />
      </div>
    </div> -->


    <!-- 		<div class="content_box">
			<div class="top_box">
				<div class="top_list">
					<div>推拿</div>
					<div>理疗</div>
					<div>泰式SPA</div>
					<div>纤体养生</div>
					<div>通络</div>
				</div>
				<div class="img_box">
					<img class="wow animate__fadeInLeftBig" src="/static/home/img_1.png" />
					<img class="wow animate__fadeInRightBig" src="/static/home/img_2.png" />
				</div>
			</div>
		</div> -->
    <!-- 商家展示 -->
    <!-- 		<div class="shop_desc wow animate__fadeInUp">
			<div class="title">
				<div>EXCELLENT</div>
				<div>BUSINESS DISPLAY</div>
				<div>优秀商家展示</div>
			</div>
			<div class="swiper-container" ref="swiperRef">
				<div class="swiper-wrapper">
					<div class="swiper-slide">
						<div class="img_list">
							<img src="/static/home/js_3.png" />
							<img src="/static/home/js_2.png" />
							<img src="/static/home/js_1.png" />
						</div>
					</div>
					<div class="swiper-slide">
						<div class="img_list">
							<img src="/static/home/js_3.png" />
							<img src="/static/home/js_2.png" />
							<img src="/static/home/js_1.png" />
						</div>
					</div>
				</div>
			</div>
			<div class="tool">
				<img src="/static/home/left.png" @click="handleClickBtn(0)" />
				<img src="/static/home/right.png" @click="handleClickBtn(1)" />
			</div>
		</div> -->

    <div>
      <img class="imgcenter" src="/static/home/aishangquanguo.png" />
    </div>
    <!-- 
		<div class="box center_box">
			<div class="wow animate__fadeInUp">
				<div>SERVICE ITEMS</div>
				<div>服务项目</div>
			</div>
			<div class="wow animate__fadeInUp">不断拓展多元化服务品类及服务模式</div>
			<div class="product_list">
				<div class="product_list_item wow animate__fadeInUp" v-for="(k, i) of list" :key="i">
					<div class="product_list_item_top_box">
						<div class="product_list_item_name">
							{{ k.name }}
							<span style="font-size: 20px; position: reactive; top: -4px" v-if="k.con">{{ k.con }}</span>
						</div>
						<div class="time">
							<span>{{ k.time }}</span>
							<span>MINUTES</span>
						</div>
					</div>

					<div class="product_list_item_text">
						<p v-for="(item, index) of k.text" :key="index">{{ item }}</p>
					</div>
					<img :src="k.img" mode="widthFix" class="img" />
				</div>
				<div class="big_show">
					<div class="big_item_box wow animate__fadeInUp">
						<p>完善的服务流程 安全高效有保障（下单流程+接单流程）</p>
						<p>专业导师团队 0费用0基础教学培训</p>
						<p>城市多场景覆盖</p>
					</div>
				</div>
			</div>
			<div class="mini_show">
				<div class="center_text wow animate__fadeInUp">
					完善的服务流程 安全高效有保障（下单流程+接单流程） 专业导师团队
					0费用0基础教学培训 城市多场景覆盖
				</div>
			</div>
		</div> -->

    <my-footer></my-footer>
    <el-dialog v-model="showDownLoad" width="300">
      <div class="down_box">
        <div @click="handleDownLoad">
          <img src="/static/az.png" alt="" />
          <p>安卓下载</p>
        </div>
        <div @click="handleDownLoad">
          <img src="/static/ios.png" alt="" />
          <p>IOS下载</p>
        </div>
      </div>
    </el-dialog>
    <div class="ts" v-show="show">
      <img src="/static/ts.png" alt="" />
    </div>
    <img
      src="/static/download.png"
      alt=""
      class="download"
      @click="handleDownLoad"
    />
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";

import myFooter from "@/components/my-footer/index.vue";
import topNav from "@/components/nav/index.vue";
import mySwiper from "swiper";
import { WOW } from "wowjs";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, Navigation, Scrollbar } from "swiper";
const modules = [Autoplay, Pagination, Navigation, Scrollbar];
const list = reactive([
  {
    time: 60,
    img: "/static/product/img_1.png",
    name: "女神尊享spa",
    text: [
      "植物精油与按摩手法相结合",
      "经络养生,舒缓压力",
      "植物精油,润泽肌肤",
    ],
    con: "(限女性)",
  },
  {
    time: 60,
    img: "/static/product/img_2.png",
    name: "泰式古法",
    text: [
      "中式传统按摩与泰式拉伸相结合",
      "瑜伽拉伸,增强柔韧性",
      "焕发活力,平衡阴阳",
      "缓解关节韧带阻塞",
    ],
  },
  {
    time: 70,
    img: "/static/product/img_3.png",
    name: "暖宫养护",
    text: [
      "按摩手法与大炮艾灸相结合",
      "温经散寒  活血祛瘀,新陈代谢,补元益气",
      "缓解手脚冰凉、宫寒、痛经、加强肠道蠕动，疏松粘连",
    ],
    con: "(限女性)",
  },
  {
    time: 80,
    img: "/static/product/img_4.png",
    name: "精油开背",
    text: [
      "植物精油spa与中式传统按摩相结合",
      "经络养生，肠胃养护，植物精油",
      "润泽肌肤，放松肌肉，舒缓压力",
    ],
  },
  {
    time: 90,
    img: "/static/product/img_5.png",
    name: "全身芳香SPA",
    text: [
      "植物精油与按摩手法相结合",
      "经络养生 舒缓压力，脾胃养护",
      "加速代谢，植物精油 润泽肌肤",
    ],
  },
  {
    time: 100,
    img: "/static/product/img_6.png",
    name: "芳香SPA+热灸",
    text: [
      "植物精油与按摩手法、能量热石相结合",
      "砭石温灸，温经散寒，养筋荣脉，淋巴排毒",
      "植物精油，润泽肌肤，潜阳安神，调和气血",
      "胸腺养护，宽胸理气，脾胃养护  新陈代谢",
    ],
  },
  {
    time: 120,
    img: "/static/product/img_7.png",
    name: "芳香SPA+眼护",
    text: [
      "聆听大自然秘语，结合五感六觉进入沉浸式spa之旅",
      "听：静听颂钵  放空心灵 回归自然",
      "嗅：植物香薰  调节呼吸 平抚身心",
      "触：缓解疲劳  疏经活络 解压助眠",
    ],
  },
]);
const swiperRef = ref(null);
const swiperExl = ref(null);
const handleClickBtn = (val) => {
  if (swiperExl.value) {
    // console.log(swiperExl.value);
    swiperExl.value.slideTo(val);
  }
};
const wow_example = new WOW({
  boxClass: "wow",
  animateClass: "animate__animated",
  offset: 0,
  mobile: true,
  live: true,
});
const showDownLoad = ref(false);
const show = ref(false);
const handleDownLoad = () => {
  const ua = navigator.userAgent.toLowerCase();
  const isiOS = ua.indexOf("android") > -1 || ua.indexOf("linux") > -1; //ios终端
  const isWeixin = ua.match(/MicroMessenger/i) == "micromessenger";
  if (!isiOS) {
    // location.href =
    //   "https://apps.apple.com/cn/app/%E7%88%B1%E5%B0%9A%E5%BE%80%E7%BA%A6/id6470423165";
	location.href =
	  "https://apps.apple.com/cn/app/%E5%BE%80%E7%BA%A6/id6504481191";
    return;
  }
  if (isWeixin) {
    show.value = true;
  } else {
    location.href = "https://obs.cqwydj.com/wydj.apk";
  }
};
onMounted(() => {
  swiperExl.value = new mySwiper(swiperRef.value, {
    slidesPerView: 1,
  });
  wow_example.init();
});
</script>
<style lang="scss">
.shangjiamodel {
  width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.navcity {
  width: 300px;
  display: flex;
  flex-direction: column;
  font-size: 30px;
  height: 554px;
  justify-content: center;
  text-align: right;
  padding-right: 30px;
}

.navimg {
  width: 1100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.xuanzhong {
  color: #7070d3;
  height: 200px;
}

.weixuanzhogn {
  height: 100px;
}

.jishicssimg {
  width: 352px;
  height: 554px;
}

.jishititle {
  font-size: 72px;
  color: #3b445c;
  font-weight: bold;
  width: 1200px;
  height: 300px;
  line-height: 300px;
  padding-left: 120px;
}

.jishipagetubiao1 {
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  height: 300px; /* 容器的高度 */
  width: 100px;
}

.pagecss {
  width: 76px;
  height: 76px;
}

.ts {
  width: 100vw;
  height: 100vh;

  & > img {
    width: 100%;
  }

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
}

.swiper-container {
  overflow: hidden;
}

.imgcenter {
  width: 100%;
 
}

.big_item_box {
  width: 925px;
  height: 580px;
  background: #e6e7e7;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 50px;

  & > p {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: bold;
    font-size: 32px;
    color: #606060;
    line-height: 70px;
  }
}

.home_content {
  .content_product {
    width: 80%;
    margin: 0 auto;
  }

  .content_product_top {
    height: 336px;
    font-size: 72px;
    color: #3b445c;
    text-align: center;
    line-height: 336px;
  }

  .content_product_center {
    display: flex;
    flex-direction: row;
  }

  .procutxiangmu1 {
    display: flex;
    flex-direction: column;
    width: 500px;
    align-items: center;
  }

  .xiangmucss {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    color: #fff;
    background-color: #7070d3;
    font-size: 40px;
    text-align: center;
    line-height: 180px;
  }

  .xiangmuwenzi {
    width: 180px;
    font-size: 30px;
    color: #828282;
    text-align: center;
    padding-top: 56px;
  }

  .content_product_middle {
    text-align: center;
  }

  .btndetail {
    width: 232px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background-color: #43afa8;
    color: #fff;
    margin: 60px auto;
  }

  .product_box {
  }

  .content_box {
  }

  .download {
    position: fixed;
    bottom: 10%;
    right: 5%;
    width: 100px;
    height: 100px;
    z-index: 999;
  }

  .down_box {
    display: flex;
    justify-content: space-around;

    & > div {
      width: 80px;
      text-align: center;
    }

    & img {
      width: 60px;
      height: 60px;
      margin: 0 auto;
      margin-bottom: 5px;
    }
  }

  .nav {
    padding-right: 149px;
  }

  .swiper_box {
    position: relative;

    .phone {
      position: absolute;
      bottom: 0px;
      right: 2%;
      width: 15%;
      z-index: 10;
    }

    .logo {
      position: absolute;
      top: 0;
      left: 0;
      width: 11%;
      z-index: 10;
      transform: translateY(-100%);
    }
  }

  .top_box {
    position: relative;

    .top_list {
      box-sizing: border-box;
      background: #42bbb4;
      display: flex;
      align-items: center;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;

      & > div {
        cursor: pointer;
      }

      & > div:not(:first-child) {
        border-left: 1px solid #fff;
      }
    }

    .img_box {
    }
  }

  .shop_desc {
    background: #42bbb4;
    position: relative;

    .title {
      font-family: DIN Light;
      font-weight: 400;
      color: #ffffff;
    }

    .img_list {
      display: flex;
      justify-content: space-between;
    }

    .tool {
      position: absolute;
      display: flex;
      justify-content: space-between;

      & > img {
        cursor: pointer;
      }
    }
  }

  .project_content {
    margin: 0 auto;

    .title {
      font-family: DIN Light;
      font-weight: 400;
      color: #606060;
    }

    .desc {
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #606060;
    }

    .project_list {
      display: flex;
      flex-wrap: wrap;

      // justify-content: space-between;
      &_item {
        & > img {
          width: 100%;
        }

        &_name {
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #606060;
        }

        &_text {
          font-family: Source Han Sans CN;
          font-weight: 300;
          color: #606060;
        }
      }
    }
  }
}

.box {
  margin: 0 auto;
}

@media screen and (min-width: 0px) {
  .carousel {
    height: 110px;
  }

  .box {
    width: 100%;
    margin: 0 auto;
  }

  .top_box {
    position: relative;

    .top_list {
      box-sizing: border-box;
      height: 30px;
      font-size: 14px;
      justify-content: center;

      & > div {
        padding: 0 5px;
        cursor: pointer;
      }

      & > div:not(:first-child) {
        border-left: 1px solid #fff;
      }
    }

    .img_box {
      margin-top: 5px;

      & > img:first-child {
        width: 80%;
        margin: 0 auto;
        display: block;
      }

      & > img:last-child {
        width: 100%;
        margin: 0 auto;
        display: block;
        margin-top: 10px;
      }
    }
  }

  .shop_desc {
    padding: 10px;

    .title {
      font-size: 18px;

      & > div:last-child {
        margin-top: 10px;
        font-size: 20px;
      }
    }

    .img_list {
      margin-top: 20px;

      & > img {
        width: 100px;
      }
    }

    .tool {
      width: 50px;
      height: 20px;
      top: 50px;
      right: 20px;

      & > img {
        width: 20px;
      }
    }
  }

  .project_content {
    margin-top: 20px;
    padding: 0 20px;

    .title {
      font-size: 20px;
    }

    .desc {
      margin-top: 10px;
      font-size: 14px;
    }

    .project_list {
      margin-top: 10px;
      justify-content: space-between;

      &_item {
        width: 45%;
        margin-bottom: 44px;

        & > img {
          width: 100%;
        }

        &_name {
          font-size: 16px;
          margin-top: 10px;
        }

        &_text {
          margin-top: 10px;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }

  .center_box {
    padding: 5px;

    & > div:nth-child(1) {
      color: #42bbb4;
      margin-top: 20px;

      & > div:first-child {
        font-size: 20px;
      }

      & > div:last-child {
        font-size: 18px;
        margin-top: 10px;
      }
    }

    & > div:nth-child(2) {
      font-size: 20px;
      font-weight: bold;
      margin-top: 20px;
      color: #606060;
    }
  }

  .product_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;

    &_item {
      width: 100%;
      height: 360px;
      position: relative;
      padding: 10px;
      background-color: #e6e7e7;
      margin-bottom: 20px;

      &_top_box {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;

        .time {
          width: 120px;
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          height: 30px;

          & > span:nth-child(1) {
            font-weight: 500;
            font-size: 20px;
            color: #42bbb4;
            line-height: 30px;
          }

          & > span:nth-child(2) {
            font-weight: 400;
            font-size: 12px;
            color: #42bbb4;
            line-height: 20px;
          }
        }
      }

      &_name {
        font-size: 20px;
        padding-bottom: 5px;
      }

      &_text {
        margin-top: 10px;
        font-size: 12px;
        line-height: 20px;
        color: #606060;
      }

      & > .img {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      & > .icon {
        height: 10px;
        position: absolute;
        top: 5px;
        right: 1px;
      }
    }
  }

  .center_text {
    margin-top: 30px;
    font-size: 20px;
    font-weight: bold;
    color: #606060;
  }
}

// @media screen and (min-width: 768px) {
//   .top_box {
//     position: relative;
//     .top_list {
//       box-sizing: border-box;
//       height: 30px;
//       font-size: 14px;
//       justify-content: center;
//       & > div {
//         padding: 0 5px;
//         cursor: pointer;
//       }
//       & > div:not(:first-child) {
//         border-left: 1px solid #fff;
//       }
//     }
//     .img_box {
//       margin-top: 5px;
//       & > img:first-child {
//         width: 80%;
//         margin: 0 auto;
//         display: block;
//       }
//       & > img:last-child {
//         width: 100%;
//         margin: 0 auto;
//         display: block;
//         margin-top: 10px;
//       }
//     }
//   }
//   .shop_desc {
//     padding: 10px;
//     .title {
//       font-size: 18px;
//       & > div:last-child {
//         margin-top: 10px;
//         font-size: 20px;
//       }
//     }

//     .img_list {
//       margin-top: 20px;
//       & > img {
//         width: 100px;
//       }
//     }
//     .tool {
//       width: 50px;
//       height: 20px;
//       top: 50px;
//       right: 20px;
//       & > img {
//         width: 20px;
//       }
//     }
//   }
//   .project_content {
//     margin-top: 20px;
//     padding: 0 20px;
//     .title {
//       font-size: 20px;
//     }
//     .desc {
//       margin-top: 10px;
//       font-size: 14px;
//     }
//     .project_list {
//       margin-top: 10px;
//       justify-content: space-between;
//       &_item {
//         width: 45%;
//         margin-bottom: 44px;
//         & > img {
//           width: 100%;
//         }
//         &_name {
//           font-size: 16px;
//           margin-top: 10px;
//         }

//         &_text {
//           margin-top: 10px;
//           font-size: 12px;
//           line-height: 20px;
//         }
//       }
//     }
//   }
// }
@media screen and (min-width: 998px) {
  .carousel {
    height: 440px;
  }

  .box {
    width: 1400px;
    margin: 0 auto;
  }

  .content_box {
    width: 1400px;
    margin: 0 auto;
  }

  .top_box {
    position: relative;

    .top_list {
      box-sizing: border-box;
      height: 42px;
      padding-left: 496px;
      font-size: 21px;

      & > div {
        padding: 0 10px;
        cursor: pointer;
      }

      & > div:not(:first-child) {
        border-left: 1px solid #fff;
      }
    }

    .img_box {
      margin-top: 59px;
      padding-left: 30px;
      display: flex;
      justify-content: space-between !important;

      & > img:first-child {
        width: 400px;
      }

      & > img:last-child {
        width: 900px;
      }
    }
  }

  .shop_desc {
    width: 1400px;
    height: 852px;
    margin: 197px auto 188px auto;
    padding: 60px;

    .title {
      font-size: 43px;

      & > div:last-child {
        font-size: 29px;
        margin-top: 20px;
      }
    }

    .img_list {
      display: flex;
      justify-content: space-between;
      margin-top: 60px;

      & > img {
        width: 400px;
      }
    }

    .tool {
      width: 155px;
      height: 55px;
      top: 150px;
      right: 87px;

      & > img {
        width: 55px;
      }
    }
  }

  .project_content {
    width: 1400px;
    margin: 0 auto;
    margin-top: 188px;

    .title {
      font-size: 43px;
    }

    .desc {
      margin-top: 21px;
      font-size: 29px;
    }

    .project_list {
      margin-top: 55px;
      display: flex;
      flex-wrap: wrap;
      justify-content: start;

      &_item {
        width: 320px;
        margin-bottom: 44px;

        & > img {
          width: 100%;
        }

        &_name {
          font-size: 21px;
          margin-top: 15px;
        }

        &_text {
          margin-top: 10px;
          font-size: 13px;
          line-height: 33px;
        }
      }

      & > .project_list_item:not(:nth-child(4n + 1)) {
        margin-left: 20px;
      }
    }
  }

  .center_box {
    padding-left: 0px;

    & > div:nth-child(1) {
      margin-top: 73px;

      & > div:first-child {
        font-size: 43px;
      }

      & > div:last-child {
        font-size: 29px;
        margin-top: 27px;
      }
    }

    & > div:nth-child(2) {
      font-size: 25px;
      font-weight: bold;
      margin-top: 42px;
      color: #606060;
    }
  }

  .product_list {
    margin-top: 55px;

    &_item {
      width: 450px;
      height: 580px;
      position: relative;
      padding: 40px 27px;
      background-color: #e6e7e7;
      margin-bottom: 68px;

      &_top_box {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        justify-content: space-between;
        border-bottom: 2px solid #606060;

        .time {
          margin-left: 10px;
          width: 166px;
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;

          & > span:nth-child(1) {
            font-weight: 500;
            font-size: 65px;
            color: #42bbb4;
            line-height: 80px;
          }

          & > span:nth-child(2) {
            font-weight: 400;
            font-size: 19px;
            color: #42bbb4;
          }
        }
      }

      &_name {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #42bbb4;
        padding: 20px 0px;
      }

      &_text {
        margin-top: 23px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 300;
        color: #606060;
        line-height: 26px;
      }

      & > .img {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      & > .icon {
        height: 48px;
        position: absolute;
        top: 42px;
        right: 26px;
      }
    }
  }

  .center_text {
    margin-top: 93px;
    font-size: 25px;
    font-weight: bold;
    color: #606060;
  }
}
</style>
